import React, { useState, useEffect } from "react"
import styled from "styled-components"
import PageWrapper from "./PageWrapper"
import { AccountButtonDark, AccountButtonLightLink, H2 } from "./AccountStyles"
import AccountInputField from "./AccountInputField"
import { useUserContext } from "../../context/UserContext"
import {
  validateEmail,
  validateFormFields,
} from "../../services/account/popupFormHelper"
import { loginUser } from "../../services/user/user"
import ErrorPopup from "./ErrorPopup"
import { Link } from "gatsby"
import fonts from "../../styles/fonts"
import colors from "../../styles/colors"
import OnClickButton from "../Core/OnClickButton"

const StyledLink = styled(Link)`
  color: #8c8984;
  line-height: 1.25;
  ${fonts.openSansMedium};
  font-size: 0.79rem;
  text-decoration: none;
  &:hover,
  &:focus {
    color: ${colors.royalBlue};
  }
`
const ButtonWrapper = styled.div`
  margin: 1.5rem 0;
  button {
    width: 100%;
  }
`

const invalidEmailError = "Please enter a valid email address"

const Login = () => {
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  })
  const [fieldErrors, setFieldErrors] = useState({
    email: "",
    password: "",
  })
  const { user, setUser } = useUserContext()
  const [formError, setFormError] = useState(null)

  const handleChange = (name, value) => {
    let updatedFormData = formData

    updatedFormData[name] = value
    setFormData(updatedFormData)
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    setFormError(null)
    setFieldErrors({
      email: "",
      password: "",
    })
    let hasError = validateFormFields(formData, fieldErrors, setFieldErrors)

    if (fieldErrors.email.trim().length > 0) {
      let validEmail = validateEmail(formData.email)
      if (!validEmail) {
        hasError = true
        const newFieldErrors = { ...fieldErrors, email: invalidEmailError }
        setFieldErrors(newFieldErrors)
      }
    }

    if (hasError) {
      return
    }

    try {
      const loggedInUser = await loginUser(formData.email, formData.password)
      if (loggedInUser) {
        window.location.href = "/account/orders"
        try{
          let old_data = {
            event_category: "account",
            // email: formData.email
            send_to: "UA-185335499-1"

          }
        
          if (typeof window.gtag !== 'undefined') {
            window.gtag('set', 'user_id', loggedInUser.id)
            window.gtag('event', 'login', { method: "email", send_to: "G-LZ50YRTV2Y"});
            window.gtag('event', 'login', old_data );
          }
        } catch(error1)
        {
          console.log(error1)
        }
      } else {
        setFormError(
          "You entered an invalid email or password. Please try again."
        )
      }
    } catch (error) {
      setUser(null)
      setFormError("An error occurred, please try again.")
      console.error(error)
    }
  }

  useEffect(() => {
    let errorTimeOut
    if (formError) {
      errorTimeOut = setTimeout(() => {
        setFormError(null)
      }, 3000)
    }
    return () => {
      clearTimeout(errorTimeOut)
    }
  }, [formError])

  return (
    <PageWrapper>
      <H2>Log in</H2>
      <form onSubmit={handleSubmit} novalidate>
        <AccountInputField
          data={formData}
          name="email"
          placeholder="Email"
          handleChange={handleChange}
          type="email"
          error={fieldErrors.email}
          label="E-mail"
        />
        <AccountInputField
          data={formData}
          name="password"
          placeholder="Password"
          handleChange={handleChange}
          type="password"
          error={fieldErrors.password}
          label="Password"
        />
        <StyledLink to="/account/forgot-password">
          Did you forgot your password?
        </StyledLink>
        <ButtonWrapper>
          <OnClickButton blue type="submit">Log in</OnClickButton>
        </ButtonWrapper>
        <AccountButtonLightLink to="/account/register">
          Create account
        </AccountButtonLightLink>
      </form>
      {formError && (
        <ErrorPopup>
          <span>{formError}</span>
        </ErrorPopup>
      )}
    </PageWrapper>
  )
}

export default Login
