import React from "react"
import Seo from "../../components/Core/Seo"
import Layout from "../../components/Layout/Layout"
import colors from "../../styles/colors"
import Login from "../../components/AccountContent/Login"

const LoginPage = ({ location }) => {
  const path = location.pathname

  return (
    <Layout dark bg={colors.white}>
      <Seo
        title="Login Page"
        description="In Good Company Login Page"
        fullTitle="Login Page"
        path={path}
      />
      <Login />
    </Layout>
  )
}

export default LoginPage
